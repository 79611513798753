//Routing import
import { BrowserRouter, Routes, Route } from "react-router-dom";

//Top imports
import React from "react";

//styling import
import "./App.css";

//Page import
import Home from "./pages/Home";

//Component Import
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function App() {
  const handleScroll = (_id) => {
    const section = document.getElementById(`${_id}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Navbar handleScroll={handleScroll} />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer handleScroll={handleScroll} />
      </BrowserRouter>
    </div>
  );
}

export default App;
