//Top Imports
import React from "react";

//motion from framer
import { motion } from "framer-motion";

//Asset import
import LOGO from "../../assets/AshenforgeLogoAnvil.png";
import NAME from "../../assets/AshenforgeLogoText.png";

//Styling imports
import style from "./style.module.css";

const Navbar = ({ handleScroll }) => {
  return (
    <section className={style.navbar_container}>
      {/* NOTE News PAGE LINK */}
      <motion.div whileTap={{ scale: 0.9 }}>
        <div onClick={() => handleScroll("news")} className={style.link}>
          <p className={style.nav_text}>News</p>
        </div>
      </motion.div>

      {/* NOTE About PAGE LINK */}
      <motion.div whileTap={{ scale: 0.9 }}>
        <div onClick={() => handleScroll("about")} className={style.link}>
          <p className={style.nav_text}>About</p>
        </div>
      </motion.div>

      <div className={style.nav_logo_wrapper}>
        <img src={LOGO} alt="Ashenforge Logo" className={style.nav_logo} onClick={() => window.location.replace("/")} />

        <img src={NAME} alt="Ashenforge in pixel art text" className={style.header_img_text} />
      </div>

      {/* NOTE Steam PAGE LINK */}
      <motion.div whileTap={{ scale: 0.9 }}>
        <a className={style.link} href="https://www.youtube.com/@AshenforgeStudio" target="_blank" rel="noreferrer">
          <p className={style.nav_text}>Youtube</p>
        </a>
      </motion.div>

      {/* NOTE Discord PAGE LINK */}
      <motion.div whileTap={{ scale: 0.9 }}>
        <a className={style.link} href="https://discord.gg/sSfQg2rJeh" target="_blank" rel="noreferrer">
          <p className={style.nav_text}>Discord</p>
        </a>
      </motion.div>
    </section>
  );
};

export default Navbar;
