//Top Imports
import React from "react";

//Styling imports
import style from "./style.module.css";

import GAME_LOGO from "../../assets/Voidborne.png";

const About = () => {
  return (
    <section className={style.about_container}>
      <div className={style.about_content_wrapper}>
        <div className={style.about_text} id="about">
          <h1>
            Forging worlds, <br />
            one pixel at a time.
          </h1>

          <p>
            Ashenforge Studio is an indie game development studio based in Southern California. Our passion lies in
            crafting immersive 2D pixel art games that combine rich storytelling with atmospheric design. We are
            dedicated to creating experiences that captivate and resonate with players long after the game has ended.
          </p>
        </div>

        <div className={style.about_text} id="news">
          <h1>In Development:</h1>
          <div className={style.game_logo_wrapper}>
            <img src={GAME_LOGO} alt="Voidborne Blade Logo" className={style.game_logo} />
          </div>
          <p>
            Our main project in development, Voidborne Blade, is a dark-themed 2D platformer set in a post-apocalyptic
            dark fantasy world. The game features:
            <ul>
              <li>
                <span>Intense Combat:</span> Challenging battles with intricate mechanics.
              </li>
              <li>
                <span>Deep Lore:</span> Unravel the mysteries of the Voidborne through environmental storytelling, NPC
                interactions, and hidden artifacts.
              </li>
              <li>
                <span>Atmospheric Design:</span> Eerie, ruined landscapes brought to life with haunting music and
                detailed pixel art.
              </li>
            </ul>
            Stay tuned for updates as we forge this experience into something unforgettable.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
