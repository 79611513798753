// NOTE Top imports
import React from "react";

// NOTE styling imports
import style from "./style.module.css";

// NOTE Logo Import
import LOGO_ICON from "../../assets/AshenforgeLogoAnvil.png";

// NOTE React Icon Imports
import { AiOutlineYoutube, AiOutlineInstagram } from "react-icons/ai";
import { MdOutlineArrowRight } from "react-icons/md";

const Footer = ({ handleScroll }) => {
  return (
    <div className={style.footer_container}>
      <div className={style.footer_content_wrapper}>
        {/* <div className={style.footer_header}>
          <div className={style.footer_logo_wrapper}>
            <img src={LOGO_ICON} alt="HD360 Productions Logo" className={style.footer_logo} />
          </div>
          <div className={style.footer_header_text}>
            <h3>Ashenforge Studio</h3>
            <h4>Forging Unforgetable Experiences.</h4>
          </div>
        </div> */}

        <div className={style.footer_nav_container}>
          <section className={style.footer_nav}>
            <h2>Navigate</h2>
            <ul className={style.footer_nav_list}>
              <li onClick={() => handleScroll("home")}>
                <MdOutlineArrowRight />
                <p>Home</p>
              </li>
              <li onClick={() => handleScroll("about")}>
                <MdOutlineArrowRight />
                <p>About</p>
              </li>
            </ul>
          </section>

          <section className={style.footer_about}>
            <h2>Get in Touch</h2>
            <ul className={style.footer_nav_list}>
              <li>
                Reach Out Via Email: <span>services@ashenforgestudio.com</span>
              </li>
            </ul>
          </section>

          <section className={style.footer_more}>
            <h2>More</h2>
            <ul className={style.footer_nav_list}>
              <li onClick={() => handleScroll("news")}>
                <MdOutlineArrowRight />
                <p>News</p>
              </li>
              <li>
                <MdOutlineArrowRight />
                <p>Games</p>
              </li>
            </ul>
          </section>
        </div>

        <div className={style.footer_socials}>
          <div className={style.footer_social_header}>
            <h2>Our Other Platforms</h2>
          </div>

          <div className={style.footer_socials_wrapper}>
            <div className={style.footer_social_wrapper}>
              <a href="https://www.youtube.com/@AshenforgeStudio" target="_blank" rel="noreferrer">
                <AiOutlineYoutube />
              </a>
            </div>
            <div className={style.footer_social_wrapper}>
              <a href="https://www.instagram.com/ashenforgestudio/" target="_blank" rel="noreferrer">
                <AiOutlineInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={style.footer_rights}>&copy; 2024-2025 Ashenforge Studio. All Rights Reserved.</div>
    </div>
  );
};

export default Footer;
