//Top Imports
import React from "react";

//Styling imports
import style from "./style.module.css";

//Import of assets
import BANNER from "../../assets/AshenforgeBanner.png";

const Header = () => {
  return (
    <section className={style.header_wrapper} id="home">
      <div className={style.header_img_wrapper}>
        <img src={BANNER} alt="Ashenforge banner art of a studio in a forge" className={style.header_img} />
      </div>
    </section>
  );
};

export default Header;
