// NOTE Top imports
import React, { useEffect, useState } from "react";

// NOTE component import
import Header from "../../components/Header";
import About from "../../components/About";

const Home = () => {
  return (
    <section>
      <div>
        <Header />
      </div>

      <div>
        <About />
      </div>
    </section>
  );
};

export default Home;
